import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 8.000000) scale(0.8, 0.8)">
        <path
          d="M30.799 71.433c-1.299 0-2.499-.014-3.699.004-.794.012-1.169-.369-1.192-1.139-.007-.233-.004-.466-.004-.7V31.208c0-1.748.097-1.842 1.888-1.842h.6c1.6 0 1.791-.188 1.795-1.757.003-1.596.18-1.775 1.762-1.776h.4c2.038 0 2.038 0 2.071-2.028.018-1.094.323-1.402 1.443-1.403h14.196c1.163 0 1.447.289 1.458 1.493.011 1.333.012 2.666 0 4-.011 1.198-.288 1.472-1.475 1.473H34.796c-1.422 0-1.622.2-1.624 1.609v4c.007 1.128.298 1.422 1.408 1.423H48.6c1.32 0 1.58.251 1.59 1.563.01 1.3.009 2.599 0 3.899-.008 1.315-.243 1.536-1.598 1.536H33.6c-1.497 0-1.702.21-1.703 1.746v24.694c0 1.434-.163 1.59-1.621 1.593-.633.001-1.266 0-1.999 0zM62.082 59.433c.001 3.098 0 6.096 0 9.094.001 1.575.161 1.738 1.696 1.738h13.691c1.48 0 1.71.237 1.712 1.752.001 1.299 0 2.598.003 3.897.009.914-.428 1.352-1.335 1.352-4.83-.001-9.66-.002-14.49 0-.87 0-1.249-.44-1.285-1.296-.093-2.235-.106-2.234-2.398-2.236-1.747-.001-1.847-.101-1.856-1.875-.007-1.374-.229-1.6-1.647-1.589-2.709.02-2.607.145-2.603-2.614.012-7.861.004-15.722 0-23.584.001-1.751.09-1.838 1.885-1.838 1.699 0 3.398 0 5 0 1.281 0 1.52.224 1.522 1.51.007 5.196 0 10.393 0 15.69z"
          fill="currentColor"
        />
        <path
          d="M59.778 35.368c-1.693 0-3.289-.02-4.884.008-.925.016-1.341-.376-1.326-1.308.025-1.496.019-2.992 0-4.488-.009-.832.362-1.222 1.199-1.217 2.061.012 4.122.011 6.184.001.752 0 1.112.383 1.119 1.096.016 1.595.015 3.191 0 4.787-.006.776-.425 1.147-1.202 1.123-.332-.011-.665-.001-1.094 0z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
